import React from 'react';
import AppWrapper from '../styles/council.css';
import withContext from '../store/withContext';

var apiEnv = process.env.NODE_ENV === 'development' ? 'https://localhost:3006' : 'https://api.hirezstudios.com';

const cats =   {
  "Live Streaming": [
    "Streamer of the Year",
    "International Streamer of the Year",
    "Clip of the Year"
  ],
  "Video Creator": [
    "Video Creator of the Year",
    "International Video Creator of the Year",
    "Video of the Year"
  ],
  "Community Contributor": [
    "Community Member of the Year",
    "Fan Artist of the Year",
    "Rising Star"
  ],
  "Pro Play & Esports": [
    "Rookie of the Year",
    "Esports Play of the Year",
    "Pro Player of the Year"
  ]
}

class Crystals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCat: undefined,
      selectedAward: undefined,
      gameId: 300,
      smiteStats: {},
      palStats: {},
      showTally: false,
      tallydate: undefined
    };
  }

  getTally = () => {
    fetch(`${apiEnv}/collect/votes/${this.state.gameId}`)
    .then(res => res.json())
    .then(res => {
      this.setState({tallyData: res})
    });
  }

  getNoms = () => {
    fetch(`${apiEnv}/collect/getCollection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json()).then(response => {
      
      let smiteStats = {};
      let palStats = {};
      response.forEach(nom => {
        if (nom.gameId === 300) {
          smiteStats[nom.category] = smiteStats[nom.category] + 1 || 0;
        }
        else palStats[nom.category] = palStats[nom.category] + 1 || 0;
      });
      response.length > 0 ? this.setState({noms: response, smiteStats: smiteStats, palStats, palStats}) : this.setState({error: "No nominations"});
    });
  }

  filterGame = (nom) => {
    return(parseInt(nom.gameId) === this.state.gameId ? true : false);
  }

  filterAward = (nom) => {
    if (this.state.selectedAward !== undefined) {
      return(nom.category === this.state.selectedAward ? true : false);
    }
    else return true;
  }

  componentDidMount() {
    this.getNoms();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showTally && (this.state.gameId !== prevState.gameId)) {
      this.getTally();
    }
  }

  render() {
    return (
      <AppWrapper>
        {(this.props.context.availableTools && this.props.context.availableTools.includes('awards2021')) && 
        <div>
          <h2>2020 Bolts and Crystals Nominees</h2>
          <div className="filters">
            <div className="filter">
              <div>Game</div>
                <select id="category-select" value={this.state.gameId} onChange={(e) => {this.setState({gameId: parseInt(e.target.value)})}}>
                  <option value="300">SMITE</option>
                  <option value="400">Paladins</option>
                </select>
            </div>
            {!this.state.showTally &&
            <>
            <div className="filter">
              <div>Category</div>
                <select id="category-select" defaultValue={this.state.selectedCat === undefined ? 'selected' : ''} onChange={(e) => {this.setState({selectedCat: e.target.value === 'undefined' ? undefined : e.target.value})}}>
                  <option value="undefined">Show All</option>
                  {Object.keys(cats).map((cat,i) =>
                  <option key={cat + i} value={cat}>{cat}</option>
                  )}
                </select>
            </div>
            <div className="filter">
              <div>Award</div>
              <select id="award-select" defaultValue="undefined" onChange={(e) => {this.setState({selectedAward: e.target.value === 'undefined' ? undefined : e.target.value})}}>
                <option value="undefined" >Show All</option>
                {this.state.selectedCat && cats[this.state.selectedCat].map((award,i) =>
                <option key={award + i} value={award}>{award}</option>  
                )}
              </select>
            </div>
            </>
            }
            <div onClick={() => {this.getTally(); this.setState(prev => {return{showTally: prev.showTally ? false : true}})}}className="button">{this.state.showTally ? 'Hide Tally' : 'Show Tally'}</div>
          </div>
          {!this.state.showTally &&
          <>
          <div className="stats">
            {(this.state.smiteStats && this.state.gameId === 300) && 
            <div className="stats-wrapper">
              {Object.keys(this.state.smiteStats).map((stat, i) =>
              <div className="stat" key={stat + i}><span>{stat}: </span>{this.state.smiteStats[stat]}</div>
              )}
            </div>
            }
            {(this.state.palStats && this.state.gameId === 400) && 
            <div className="stats-wrapper">
              {Object.keys(this.state.palStats).map((stat, i) =>
              <div className="stat" key={stat+i+'pal'}><span>{stat}: </span>{this.state.palStats[stat]}</div>
              )}
            </div>
            }
          </div>
          <div className="applicants">
          {this.state.noms && this.state.noms.filter(this.filterGame).filter(this.filterAward).map((nom,i) => 
          <div key={nom.name + i} className="applicant">
            <p><span>Game: </span> {nom.gameId === 300 ? "SMITE" : "Paladins"}</p>
            <p><span>Category: </span>{nom.category}</p>
            <p><span>Name: </span>{nom.name}</p>
            <p><span>Content Link: </span>{nom.content}</p>
            <p><span>Social Media Link: </span>{nom.social}</p>
            <p><span>Additional Info: </span>{nom.addl}</p>
          </div>
          )}
          </div>
          </>
          }
          {this.state.showTally && this.state.tallyData && 
          <div className="tally">
            {this.state.tallyData.map(vote => 
            <div key={vote._id.category+vote._id.name} className="tally-vote">
              <p><span style={{fontSize: '16px', fontWeight: 'bold'}}>{vote._id.category} - </span>{vote._id.name}: {vote.votes}</p>
            </div>)}
          </div>
          }
        </div>
        }
      </AppWrapper>
    );
  }
}

export default withContext(Crystals);
